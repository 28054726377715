import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { layouts } from '@/utils/preset'
import { mobile } from '@/utils/media'
import { mq, rgba } from '@/utils/helpers'
import Typography from './Typography'
import ParticleImage from '@/components/common/ParticleImage'

const PageTitle = props => {
  const { theme, className, children, src, ...other } = props
  return (
    <Root
      className={className}
      {...other}
    >
      <Container>
        {src && (
          <Image src={src} fixedHeight scale={400} />
        )}
        <Text variant="h3" component="h1">
          { children }
        </Text>
      </Container>
      <Lines theme={theme} />
    </Root>
  )
}

const Root = styled(layouts.components.FullWidth)`
  position: relative;
  padding: 40px 0;
  overflow: hidden;
`

const Container = styled(layouts.components.Container())``

const Image = styled(ParticleImage)`
  height: 64px;
  @media ${mq.and(mobile)} {
    height: 48px;
  }
`

const Text = styled(Typography)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
`

const Lines = styled(layouts.components.FullWidth)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: ${props => rgba(props.theme.scale[400], 0.2)};
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    width: 240px;
    bottom: 0;
    height: 4px;
    background-color: ${props => props.theme.secondary.base};
    transform: translateX(-50%);
    @media ${mq.and(mobile)} {
      width: 168px;
    }
  }
`

export default ThemeConsumer(PageTitle)
