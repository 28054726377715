import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { tabletL, tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Head from './PostHead'
import Request from './PostRequest'
import Content from '@/components/common/Content'

const ProjectPost = props => {
  const { post, ...other } = props
  return (
    <Root {...other}>
      <Head {...post} />
      <Main>
        <Request {...post} />
        <Content html={post.html} css={styles.Content} />
      </Main>
    </Root>
  )
}

const Root = styled.div``

const Main = styled.div`
  margin-top: 80px;
  padding: 0 80px;
  @media ${mq.and(tabletL)} {
    padding: 0 40px;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 40px;
    padding: 0;
  }
`

const styles = {
  Content: css`
    margin-top: 72px;
  `
}

export default ThemeConsumer(ProjectPost)
