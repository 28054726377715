import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PostList from './PostList'
import Typography from '@/components/common/Typography'
import LinkButton1 from '@/components/common/LinkButton1'

const ProjectOtherPostList = props => {
  const { post, ...other } = props
  return (
    <Root {...other}>
      <Title variant="h2">その他の事例</Title>
      <List ignorePaths={[post.path]} />
      <Foot>
        <Button to="/projects/" component={Link} scale="800">受託開発実績トップ</Button>
      </Foot>
    </Root>
  )
}

const Root = styled.div``

const Title = styled(Typography)`
  text-align: center;
`

const List = styled(PostList)`
  margin-top: 48px;
  @media ${mq.and(mobile)} {
    margin-top: 24px;
  }
`

const Foot = styled.div`
  margin-top: 72px;
  text-align: center;
  @media ${mq.and(mobile)} {
    margin-top: 40px;
  }
`

const Button = styled(LinkButton1)`
  width: 278px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

export default ThemeConsumer(ProjectOtherPostList)
