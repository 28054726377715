import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { getThemeColor } from '@/utils/themes'
import Typography from '@/components/common/Typography'
import Tag from '@/components/common/Tag'

const ProjectPostHead = props => {
  const { theme, client, title, clientType, platforms, stack, ...other } = props
  return (
    <Root {...other}>
      <ClientType color="primary">{clientType}</ClientType>
      <Client variant="h4" component="p">{client}</Client>
      <Title variant="h1" component="h2">{title}</Title>
      {((platforms && platforms.length) || (stack && stack.length)) && (
        <Lists>
          {platforms && platforms.length && (
            <List>
              <List_Title variant="h5" theme={theme}>種別</List_Title>
              <List_Parent>
                {platforms.map((name, index) => (
                  <List_Child key={index} variant="h5" component="li">{name}</List_Child>
                ))}
              </List_Parent>
            </List>
          )}
          {stack && stack.length && (
            <List>
              <List_Title variant="h5" theme={theme}>言語・ツール</List_Title>
              <List_Parent>
                {stack.map((name, index) => (
                  <StackList_Child key={index} variant="h5" component="li" theme={theme}>{name}</StackList_Child>
                ))}
              </List_Parent>
            </List>
          )}
        </Lists>
      )}
    </Root>
  )
}

const Root = styled.div``

const ClientType = styled(Tag)`
  flex-grow: 0;
  flex-shrink: 0;
`

const Client = styled(Typography)`
  margin-top: 24px;
`

const Title = styled(Typography)`
  margin-top: 24px;
`

const Lists = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 24px;
`

const List = styled.div`
  &:not(:first-of-type) {
    margin-top: 4px;
  }
  display: flex;
`

const List_Title = styled(Typography)`
  flex-grow: 0;
  flex-shrink: 0;
  color: ${props => props.theme.scale[300]};
`

const List_Parent = styled.ul`
  margin-left: 8px;
  display: flex;
  flex-wrap: wrap;
`

const List_Child = styled(Typography)`
  margin-right: 4px;
  &::after {
    content: ',';
  }
  &:last-of-type::after {
    content: '';
  }
`

const StackList_Child = styled(List_Child)`
  color: ${props => getThemeColor('primary', props.theme)};
`

export default ThemeConsumer(ProjectPostHead)
