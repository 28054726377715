import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/base/Page'
import SEO from '@/components/head/seo'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageTitle from '@/components/common/PageTitle'
import imgTitle from '@/images/title-projects.png'
import Post from '@/components/modules/Project/Post'
import OtherPostList from '@/components/modules/Project/OtherPostList'
import Breadcrumb from '@/components/common/Breadcrumb.container'
import ContactLead from '@/components/modules/Contact/Lead'
import RecruitLead from '@/components/modules/Recruit/Lead'

const ProjectPage = props => {
  const { markdownRemark } = props.data
  const { frontmatter, html } = markdownRemark
  const post = {
    ...frontmatter,
    html
  }
  return (
    <Root>
      <SEO location={props.location} title={post.title} description=""/>
      <Head>
        <PageTitle src={imgTitle}>受託開発事例{post.number}</PageTitle>
      </Head>
      <Main>
        <Row><Post post={post}/></Row>
        <Row><OtherPostList post={post}/></Row>
        <Row css={styles.Breadcrumb}><Breadcrumb title={post.title} path={post.path}/></Row>
        <Row css={styles.Contact}><ContactLead /></Row>
        <Row css={styles.Recruit}><RecruitLead /></Row>
      </Main>
    </Root>
  )
}

const Root = styled.div``

const Head = styled.div``

const Main = styled.div``

const Row = styled.div`
  padding: 64px 0;
  @media ${mq.and(mobile)} {
    padding: 32px 0;
  }
`

const styles = {
  Breadcrumb: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Contact: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `,
  Recruit: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `
}

export default PageBase(ProjectPage)

export const projectTemplateQuery = graphql`
  query projectTemplateQuery($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        postType
        date
        path
        number
        title
        client
        clientType
        platforms
        stack
        featuredImage {
          publicURL
        }
        request
      }
    }
  }
`
